
import { defineComponent, onMounted, ref } from "vue"
import { Tooltip } from "bootstrap"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.js"
import store from "@/store"

export default defineComponent({
  name: "connections",
  components: {},
  data() {
    let loading = ref<boolean>(true)

    onMounted(() => {
      Array.from(
        document.querySelectorAll('div[data-bs-toggle="tooltip"]')
      ).forEach(tooltipNode => new Tooltip(tooltipNode, {
        placement: "right"
      }))
    })

    const accountAPIKeys = [] as Record<string, any>[]

    const accountNum = store.getters.activeAccountInfo.id

    return {
      loading,
      loadingKeys: [] as Record<string, any>[],
      accountNum,
      accountAPIKeys,
      newKeyName: ""
    }
  },
  async created() {
    this.accountAPIKeys = await this.apiKeys()
    this.loading = false
  },
  methods: {
    CopyToClipboard(text) {
      navigator.clipboard.writeText(text)
    },
    async confirmKeyDeactivation(key) {
      if (confirm("Are you sure you want to deactivate the key \"" + key.friendly_name + "\"?")) {
        await this.deactivateKey(key)
      }
    },
    async deactivateKey(key) {
      this.loadingKeys.push(key.id)

      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.delete(`/accounts/${this.accountNum}/api-keys/${key.id}`)
      } catch (e) {
        console.error("Problem deleting api keys", e)
      }

      if (resp.status == 204) {
        this.accountAPIKeys = this.accountAPIKeys.filter((deleteKey) => deleteKey.id != key.id)
        this.loadingKeys = this.loadingKeys.filter((loadingKey) => loadingKey != key.id)
      } else {
        await this.noUpdate("Unable to deactivate API Key")
      }
    },
    async apiKeys() {
      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.get(`/accounts/${this.accountNum}/api-keys`)
      } catch (e) {
        console.error("Problem fetching api keys", e)
      }

      if (resp) {
        return resp.data.map((key) => {
          return key._attributes
        })
      }
    },
    async addKey(name) {
      let params = { friendly_name: name }

      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.post(`/accounts/${this.accountNum}/api-keys`,
          { data: params })
      } catch (e) {
        console.error("Problem fetching api keys", e)
      }

      if (resp) {
        this.accountAPIKeys.push({ id: resp.data.data.id, ...resp.data.data.attributes })
        this.newKeyName = ""
      } else {
        await this.noUpdate("Unable to create API Key")
      }
    },
    async updateName(key) {
      this.loadingKeys.push(key.id)

      let params = { ...key }

      ApiService.setHeader()

      let resp
      try {
        resp = await ApiService.put(`/accounts/${this.accountNum}/api-keys/${key.id}`,
          { data: params })
      } catch (e) {
        console.error("Problem saving api keys", e)
      }

      if (resp.data.data) {
        this.accountAPIKeys.map((key) => {
          if (key.id == resp.data.data.attributes.id) {
            key.friendly_name = resp.data.data.attributes.friendly_name
          }
        })

        this.loadingKeys = this.loadingKeys.filter((loadingKey) => loadingKey != key.id)
      } else {
        await this.noUpdate("Unable to update API Key name")
      }

    },
    async noUpdate(message) {
      let resp = await Swal.fire({
        text: message,
        icon: "success",
        confirmButtonText: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-light-primary"
        }
      })

      if (resp) {
        this.accountAPIKeys = await this.apiKeys()
      }

    }
  }
});
